#button-grid{
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

}

#button-grid > div{
  margin-top: 10px;
}


.button-group{
  display: flex;
  /* grid-template-columns: 2fr 1fr; */
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: stretch;
}

/* .button-group .button{
  margin: 0px;
} */

.button-group .button{
  margin-top: 0;
  flex: 1;
  margin-bottom: 0;
  width: fit-content;
}

.button-group .button.timed{
  flex:none;
}

.sign-out{
  color: lightgray;
  padding: 10px;
  margin-top: 20px;
  text-align: right;
  cursor: pointer;
}

@media (max-width: 500px){
  #button-grid{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 350px){
  #button-grid{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

