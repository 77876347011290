.button{
    background-color: #203675;
    color: white;
    /* font-weight: bold; */
    margin: 5px;
    padding: 10px;
    border: 3px solid black;
    cursor: pointer;
    /* display: inline-block; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* font-size: em; */;
    white-space: nowrap;
    overflow: scroll;
    text-overflow: clip;
    position: relative;


    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    
}

/* Hide scrollbar for Chrome, Safari and Opera */
.button::-webkit-scrollbar {
    display: none;
}

.button.disabled{
    opacity: 0.5;
    filter: saturate(0%);
}

.button::after{
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 15px;
    height: 100%;
    background: linear-gradient(90deg, rgba(32, 55, 117, 0), #203675);
    /* background: linear-gradient(90deg, red, green); */
}

.button.active::after, .button.timed::after{
    display: none;
}

.button.timed{
    background-color: #0f1a3a;
    justify-content: center;
    text-align: center;
    font-family: monospace;
    font-weight: bold;
}


.button.active{
    background-color: rgb(0, 190, 0);
    color: white;
}

.button.timed{
    
}

.button ion-icon{
    padding-left: 3px;
    vertical-align: baseline;
}