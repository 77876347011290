.login{
    color: white;
    padding: 2em;
}

.login form label{
    display: block;
    margin: 20px 0px;
}

.login form input {
    display: block;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    background: rgba(255, 255, 255, 0.3);
    border: none;
    border-radius: 5px;
    padding: 15px 20px;
    color: white;
    font-size: 1em;
    box-sizing: border-box;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 0.5;
  }

.login form input:focus{
    outline: none;
}

.login form .sign-in-button{
    border-radius: 5px;
    color: white;
    padding: 15px 20px;
    background: #203675;
    margin: 10px 0px;
    margin-top: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: 250ms;
    display: inline-block;

    border: none;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
}

.login form .sign-in-button:hover{
    background: #0f1a3a;
}

